import $ from "jquery";
import SmoothScroll from "smooth-scroll";

const slideScroll = () => {
  let $slide = $("#industries-slider-pagination");
  if ($slide.length) {
    let isScolling = true;
    let $html = document.querySelector("html");
    let $sliderContainer = $(".industries-container");
    // $html.style.overflowY = "hidden";

    $sliderContainer.on("wheel touchmove", function(e) {
      if (isScolling) {
        e.preventDefault();
        if (e.originalEvent.deltaY < 0) {
          $slide.slick("slickNext");
        } else {
          $slide.slick("slickPrev");
        }
      }
    });

    const scrollSection = lastSlide => {
      let totalSlides =
        $("#industries-slider .slick-slide:not(.slick-cloned)").length - 1;

      if (lastSlide == totalSlides && isScolling) {
        isScolling = false;
      }
      if (isScolling == false) {
        $html.style.overflowY = "auto";
      }

      if (isScolling == false && lastSlide == totalSlides) {
        let scroll = new SmoothScroll();
        let anchor = document.querySelector("#map");
        scroll.animateScroll(anchor, false, {
          updateURL: false
        });
      }
    };

    // $slide.on("init", function() {
    //   $html.style.overflowY = "hidden";
    // });
    // $slide.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
    //   scrollSection(nextSlide);
    // });
  }
};

export default slideScroll;
