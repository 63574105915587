import { getScreenWidth, isMobile } from "./helper";
import $ from "jquery";

const mapRegion = () => {
  const container = document.getElementById("distributors-map-container");
  if (!container) return false;

  const regions = container.querySelectorAll(".card");
  let selectedRegions = [];

  regions.forEach(region => {
    selectedRegions.push(region.getAttribute("data-region"));
  });

  const selectedRegionsIDs = selectedRegions.map(el => "#" + el);
  const mapRegions = document.querySelectorAll(selectedRegionsIDs);
  const headerHeight = document.getElementById("masthead").offsetHeight;
  // const paddingTop = window
  //   .getComputedStyle(document.querySelector(".map-section"), null)
  //   .getPropertyValue("padding-top")
  //   .replace("px", "");

  mapRegions.forEach(region => {
    const regionName = region.getAttribute("id");

    if (regionName) {
      region.classList.add("selected-region");

      region.addEventListener("click", event => {
        if (event.target.classList.contains("active")) {
          regions.forEach(el => el.classList.remove("active"));
          event.target.classList.remove("active");
          return;
        }

        const shapeSize = event.target.getBoundingClientRect();
        const shapeHeight = shapeSize.height;
        const shapeWidth = shapeSize.width;

        const regionPosX = shapeSize.left;
        const regionPosY =
          $(event.target).offset().top -
          $(".map-container").offset().top +
          shapeHeight / 2;

        mapRegions.forEach(el => el.classList.remove("active"));
        event.target.classList.add("active");

        const clickedRegion = event.target.getAttribute("id");

        regions.forEach(el => {
          el.classList.remove("active");
          if (clickedRegion == el.getAttribute("data-region")) {
            const logosHeight = el.getBoundingClientRect().height;
            const logosWidth = el.getBoundingClientRect().width;

            console.log(logosHeight);
            const truePosY = regionPosY - logosHeight / 2;
            const truePosX = regionPosX + shapeWidth / 2 - logosWidth / 2;

            el.style.top = `${truePosY}px`;

            if (regionPosX + shapeWidth > getScreenWidth())
              el.style.right = `${shapeWidth / 2}px`;
            else el.style.left = `${truePosX}px`;

            el.classList.add("active");
          }
        });
      });
    }
  });
};

export default mapRegion;
