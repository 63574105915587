export const getScreenWidth = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth;

  return width;
};

export const getScreenHeight = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    height = w.innerHeight || e.clientHeight || g.clientHeight;

  return height;
};

export const isMobile = () => {
  if (getScreenWidth() <= 768) return true;
};

export const isPhone = () => {
  if (getScreenWidth() <= 440) return true;
};

export const getCoords = elem => {
  let box = elem.getBoundingClientRect();

  let body = document.body;
  let docEl = document.documentElement;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  let top = box.top + scrollTop - clientTop;
  let left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};

export const setFullHeroHeight = container => {
  const $container = document.querySelector(container);
  if ($container) {
    const headerHeight = document.getElementById("masthead").offsetHeight;
    const height = getScreenHeight() - headerHeight;
    $container.style.height = `${height}px`;
  }
};

export const setPrefixes = (element, property, value) => {
  element.style["-webkit-" + property] = value;
  element.style["-moz-" + property] = value;
  element.style["-ms-" + property] = value;
  element.style["-o-" + property] = value;
  element.style[property] = value;
};

export const isSafari = () => {
  if (
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1
  ) {
    return true;
  }
};

export const isIE = () => {
  const ua = navigator.userAgent;
  const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  if (is_ie) return true;
  else return false;
};

export const fixFullHeight = elem => {
  if (elem) {
    const windowHeight = getScreenHeight();
    elem.style.height = windowHeight + "px";
    elem.style.minHeight = windowHeight + "px";
  }
};
