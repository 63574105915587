// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";
import slideScroll from "./slideScroll";
import { isPhone } from "./helper";

/****** SLIDERS *******/
const sliders = {
  industries: $("#industries-slider"),
  industriesPagination: $("#industries-slider-pagination")
};

sliders.industries.slick({
  dots: false,
  autoplay: true,
  slidesToShow: 2,
  arrows: false,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

sliders.industriesPagination.slick({
  dots: false,
  autoplay: false,
  slidesToShow: 2,
  arrows: false,
  vertical: true,
  verticalSwiping: true
});

sliders.industriesPagination.on("beforeChange", function(
  event,
  slick,
  currentSlide,
  nextSlide
) {
  sliders.industries.slick("slickGoTo", nextSlide);
});

function goToSlideOnClick(container) {
  container.click(function(e) {
    let index = $(this)
      .closest(".slick-slide")
      .attr("data-slick-index");
    sliders.industries.slick("slickGoTo", index);
    sliders.industriesPagination.slick("slickGoTo", index);
  });
}

function changeSlides(changedSlider, slider) {
  changedSlider.on("beforeChange", function(
    event,
    slick,
    currentSlide,
    nextSlide
  ) {
    slider.slick("slickGoTo", nextSlide);
  });
}

changeSlides(sliders.industriesPagination, sliders.industries);
changeSlides(sliders.industries, sliders.industriesPagination);
goToSlideOnClick($(".industries-slider-pagination .single"));
goToSlideOnClick($(".industries-slider .single"));
if (!isPhone()) slideScroll();
