import { isSafari, isIE } from "./helper";

const hoverTransform = (container, img) => {
  //if is safari
  if (isSafari() || isIE()) return;
  let $container = document.querySelector(container);
  let $image = document.querySelector(img);
  if ($container || $image) {
    let screenWidth = window.innerWidth;
    let halfScreenWidth = screenWidth / 2;
    let screenHeight = window.innerHeight;
    let halfScreenHeight = screenHeight / 2;

    const rotateImageHandler = e => {
      let clientX = e.clientX;
      let clientY = e.clientY;

      let rotateY = 0;
      let rotateX = 0;

      if (clientX < halfScreenWidth) rotateY = (screenWidth - clientX) / -100;
      if (clientX > halfScreenWidth)
        rotateY = (screenWidth + clientX) / 2 / 100;

      if (clientY < halfScreenHeight) rotateX = (screenHeight - clientY) / 100;
      if (clientY > halfScreenHeight)
        rotateX = (screenHeight + clientY) / 2 / -100;

      $image.style.transform = `perspective(100vh) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(1, 1, 1)`;
      $image.style.webkitTransform = `perspective(100vh) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(1, 1, 1)`;
    };

    $container.addEventListener("mousemove", rotateImageHandler, false);
    $container.addEventListener("touchmove", rotateImageHandler, false);
  }
};

export default hoverTransform;
