import { isMobile, fixFullHeight } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import hoverTransform from "./hoverTransform";
import AccordionSideMenu from "./accordionSideMenu";
import mapRegion from "./mapRegion";

window.addEventListener("load", () => {
  new SiteHeader({
    setBodyPaddingTop: document
      .querySelector("body")
      .classList.contains("page-template-homepage")
      ? false
      : true
  });
  // new FloatLabels();
  new AccordionSideMenu(".accordion__side-menu-container");

  toggleMenu();
  hoverTransform(".home__hero-banner", ".home__hero-banner img");
  mapRegion();
  // setFullHeroHeight(".industries-slider-section");

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
